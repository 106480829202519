import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";
import { ApiService } from "../services/api.service";
import { firstValueFrom } from "rxjs";
import { ListSubscriptionTypes, SubscriptionTypeStateModel } from "./models/subscription-types.state.model";
import { SubscriptionType } from "../interfaces/subscription.interface";

const defaultSubscriptionTypesState = {
    subscriptionTypes: {}
};

@State<SubscriptionTypeStateModel>({
    name: 'subscriptionTypes',
    defaults: defaultSubscriptionTypesState
})

@Injectable()
export class SusbcriptionTypesState {

    @Selector([SusbcriptionTypesState])
    static subscriptionTypes(state: SubscriptionTypeStateModel): SubscriptionType[] {
        return Object.values(state.subscriptionTypes);
    }


    constructor(
        private apiService: ApiService
    ) { }

    @Action(ListSubscriptionTypes)
    async listSubscriptionTypes(ctx: StateContext<SubscriptionTypeStateModel>) {
        const subcriptionTypes = (await firstValueFrom(this.apiService.listSubscriptionTypes())).sort((a,b) => a.availableSlots < b.availableSlots ? -1 : 1);
        const subcriptionTypesMap = subcriptionTypes.reduce((prev, curr) => {
            return {
              ...prev,
              [curr.name]: curr
            }
          }, {});
          ctx.patchState({ subscriptionTypes: subcriptionTypesMap });
    }



}
